import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import "vanilla-picker/dist/vanilla-picker.csp.css";
import Picker from "vanilla-picker/csp";

import Swal from "sweetalert2";
window.Swal = Swal;
window.Picker = Picker;

window.Alpine = Alpine;

Alpine.start();

Alpine.plugin(focus);

window.addEventListener("toast-message", (event) => {
    let options = {
        toast: true,
        text: event.detail.message,
        icon: event.detail.type,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
    };
    if (event.detail.type === "success") {
        Object.assign(options, {
            timer: 2000,
            timerProgressBar: true,
        });
    }
    if (event.detail.hasOwnProperty('html')) {
        Object.assign(options, {
            html: event.detail.html
        });
    }
    if (event.detail.type === "warning") {
        Object.assign(options, {
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: "View execution output",
        });
    }

    Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "Execution output",
                html: event.detail.error,
                showCloseButton: true,
                width: "auto",
            });
        }
    });
});

window.addEventListener("confirm-delete", (event) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(var(--color-danger) / var(--tw-bg-opacity))",
        cancelButtonColor: "rgb(var(--color-primary) / var(--tw-bg-opacity))",
        confirmButtonText: "Yes, Delete!",
    }).then((result) => {
        if (result.isConfirmed) {
            Livewire.emit(event.detail.action, event.detail.id);
        }
    });
});


window.formatScientific = function(number){
    const exponent = Math.floor(Math.log10(Math.abs(number)));
    const mantissa = number / Math.pow(10, exponent);
    return `${mantissa.toFixed(2)} x 10e${exponent}`;
}

