import TomSelect from "tom-select";

window.TomSelect = TomSelect;

// window.renderToSelect = function () {
//     if (typeof $(this).attr("hidden") !== "undefined") return;

//     let options = {
//         plugins: {
//             dropdown_input: {},
//         },
//     };

//     if ($(this).data("placeholder")) {
//         options.placeholder = $(this).data("placeholder");
//     }
//     options.allowEmptyOption = true;
//     if ($(this).attr("multiple") !== undefined) {
//         options = {
//             ...options,
//             plugins: {
//                 ...options.plugins,
//                 remove_button: {
//                     title: "Remove this item",
//                 },
//             },
//             persist: false,
//             create: true,
//             onDelete: function (values) {
//                 return confirm(
//                     values.length > 1
//                         ? "Are you sure you want to remove these " +
//                               values.length +
//                               " items?"
//                         : 'Are you sure you want to remove "' + values[0] + '"?'
//                 );
//             },
//         };
//     }

//     if ($(this).data("header")) {
//         options = {
//             ...options,
//             plugins: {
//                 ...options.plugins,
//                 dropdown_header: {
//                     title: $(this).data("header"),
//                 },
//             },
//         };
//     }

//     new TomSelect(this, options);
// };

// (function () {
//     "use strict";

//     // Tom Select
//     $(".tom-select").each(renderToSelect);
// })();
