import Toastify from "toastify-js";

window.Toastify = Toastify;
//
// (function () {
//     "use strict";
//     Toastify({
//         node: $("#system-notification")
//             .clone()
//             .removeClass("hidden")[0],
//         duration: -1,
//         newWindow: true,
//         close: true,
//         gravity: "top",
//         position: "right",
//         stopOnFocus: true,
//     }).showToast();
// })();
